export const themes = {
  green: {
    primary: "#528F30",
    "primary-2": "#DAE4D5",
    "primary-3": "#E3EDDE",
  },
  //
  orange: {
    primary: "#EE902E",
    "primary-2": "#F3E4D4",
    "primary-3": "#FCEDDE",
    // "primary-3": "rgba(235, 64, 52, .16)",
  },
};

export const setGlobalCSSVariables = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const color = urlParams.get("color");

  const theme = themes[color] || themes["orange"];
  for (const [key, value] of Object.entries(theme)) {
    document.documentElement.style.setProperty(`--color-${key}`, value);
  }
};

export const getTheme = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const color = urlParams.get("color");

  const theme = themes[color] || themes["orange"];
  return theme;
};
