import { useEffect, useState } from "react";
import { Form, LoadingScreen } from "../components";

const DepositPage = () => {
  const [transactionStatus, setTransactionStatus] = useState(undefined);
  const [isLoadingInitialStatus, setIsLoadingInitialStatus] = useState(true);
  const [transactionStatusErr, setTransactionStatusErr] = useState(false);

  const [polling, setPolling] = useState(null);
  const [nextPolling, setNextPolling] = useState(null);

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");

  // 1. get transaction status (receive client name and phone number)
  const getTransactionStatus = async (id) => {
    setIsLoadingInitialStatus(true);
    const response = await fetch(
      `https://api.test.ecom.emoney.tools/core/v1/public/payment?order_id=${id}`
    );
    const data = await response.json();

    if (response.status === 200) {
      setTransactionStatus(data);
    } else {
      // setTransactionStatusErr(data.message);
      setTransactionStatusErr(true);
    }

    setIsLoadingInitialStatus(false);
  };

  useEffect(() => {
    if (id) {
      getTransactionStatus(id);
    }
  }, [id]);

  // 2. send credit card data
  const postData = async (pan, month, year, cvv) => {
    let isSuccess;

    const url =
      "https://api.test.ecom.emoney.tools/core/v1/public/payment_data";
    const headers = {
      "Content-Type": "application/json",
    };
    const data = {
      id: transactionStatus.id,
      payment_details: {
        pan,
        month,
        year,
        cvv,
      },
    };

    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (response.status === 200) {
      isSuccess = true;
    } else {
      isSuccess = false;
    }

    return isSuccess;
  };

  // 3. check status
  const checkStatus = async (onSuccess, onFail) => {
    try {
      const response = await fetch(
        `https://api.test.ecom.emoney.tools/core/v1/public/payment?order_id=${id}`
        // `https://api.ems.test.emoney.tools/api/v1/mfo/public/transaction?id=${id}`
      );
      const data = await response.json();

      if (data.status === 0) {
        //
      } else if (data.status === 150) {
        window.location.href = `https://3ds.test.emoney.tools/?id=${transactionStatus.id}`;
      } else if (data.status === 400 || response.status === 404) {
        onFail();
        clearInterval(polling);
      } else if (data.status === 500) {
        setNextPolling(
          setInterval(() => checkNextStatus(onSuccess, onFail), 5000)
        );
        clearInterval(polling);
      }
    } catch (error) {
      console.error("Polling error:", error);
    }
  };

  // 4. check next status
  const checkNextStatus = async (onSuccess, onFail) => {
    if (transactionStatus) {
      try {
        const response = await fetch(
          `https://api.ems.test.emoney.tools/api/v1/mfo/public/transaction?id=${
            transactionStatus["order_id"] ||
            transactionStatus.metadata["transaction_id"]
          }`
        );
        const data = await response.json();

        if (data.status === 0) {
          //
        } else if (data.status === 400 || response.status === 404) {
          onFail();
          clearInterval(nextPolling);
        } else if (data.status === 600) {
          onSuccess();
          clearInterval(nextPolling);
        }
      } catch (error) {
        console.error("Polling error:", error);
      }
    }
  };

  if (isLoadingInitialStatus) {
    return <LoadingScreen />;
  }

  if (transactionStatusErr) {
    return (
      <div>
        Transaction not found
        <span />
      </div>
    );
  }

  return (
    <Form
      type={
        transactionStatus.metadata && transactionStatus.metadata.service_id
          ? "service"
          : "deposit"
      }
      transactionStatus={transactionStatus}
      postData={postData}
      checkStatusPoll={(onSuccess, onFail) => {
        setPolling(setInterval(() => checkStatus(onSuccess, onFail), 5000));
      }}
      checkNextStatusPoll={(onSuccess, onFail) => {
        setNextPolling(
          setInterval(() => checkNextStatus(onSuccess, onFail), 5000)
        );
      }}
    />
  );
};

export default DepositPage;
