export const Checkboxes = ({ register, errors = {} }) => (
  <div className="flex flex-col mt-[83px] gap-y-[8px]">
    <Checkbox
      id="terms"
      name="terms"
      label="Пользовательское соглашение"
      register={register}
      errors={errors}
    />
    <Checkbox
      id="offer"
      name="offer"
      label="Оферта"
      register={register}
      errors={errors}
    />
  </div>
);

const Checkbox = ({ label, id, name, register, errors }) => (
  <div className="flex flex-col">
    <div className="flex w-full justify-between items-center">
      <a href="https://google.com" target="_blank">
        <label
          className="text-primary underline text-[14px] cursor-pointer hover:opacity-80"
          // htmlFor={id}
        >
          {label}
        </label>
      </a>

      <label className="custom-checkbox">
        <input type="checkbox" id={id} {...register(name)} />
        <span className="checkmark"></span>
      </label>
    </div>
    {errors[name] && (
      <p role="alert" className="text-sm text-red-500">
        {errors[name].message}
      </p>
    )}
  </div>
);
