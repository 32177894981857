import * as React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import DepositServicePage from "./pages/deposit_and_service";
import WithdrawPage from "./pages/withdraw";

import "./index.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <DepositServicePage />,
  },
  {
    path: "/mfo/transaction",
    element: <WithdrawPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);
