import { Oval } from "react-loader-spinner";
import { getTheme } from "../helpers";

export const LoadingScreen = () => {
  const theme = getTheme();

  return (
    <div className="w-screen h-screen flex items-center justify-center bg-gray-200">
      <Oval
        height={110}
        width={110}
        color={theme.primary}
        secondaryColor={theme["primary-3"]}
      />
    </div>
  );
};

export default LoadingScreen;
