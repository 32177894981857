import { useState, useEffect } from "react";
import { useForm, useWatch, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import valid from "card-validator";
import { Oval } from "react-loader-spinner";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";

import { ReactComponent as UserIcon } from "../assets/icons/User.svg";
import { ReactComponent as CallIcon } from "../assets/icons/Call.svg";

import { setGlobalCSSVariables, getTheme } from "../helpers";
import { Checkboxes } from "./index";

export function Form({
  type,
  transactionStatus,
  withdrawalTransactionStatus,
  postData,
  checkStatusPoll,
  checkNextStatusPoll,
}) {
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isFail, setFail] = useState(false);

  const isDeposit = type === "deposit";
  const isWithdrawal = type === "withdraw";
  const isService = type === "service";

  useEffect(() => {
    if (type !== "withdraw") {
      if (transactionStatus.status === 150) {
        setLoading(true);
      }
      if (
        transactionStatus.status === 600 ||
        transactionStatus.status === 500
      ) {
        if (checkNextStatusPoll) {
          setLoading(true);
          checkNextStatusPoll(
            () => {
              setLoading(false);
              setSuccess(true);
            },
            () => {
              setLoading(false);
              setFail(true);
            }
          );
        } else {
          setSuccess(true);
        }
      }
      if (transactionStatus.status === 400) {
        setFail(true);
      }
    }
  }, [transactionStatus, type]);

  const schema = yup
    .object({
      terms: yup
        .array()
        .of(yup.string().oneOf(["on"], "Обязательно к заполнению"))
        .min(1, "Обязательно к заполнению")
        .required("Обязательно к заполнению"),

      offer: yup
        .array()
        .of(yup.string().oneOf(["on"], "Обязательно к заполнению"))
        .min(1, "Обязательно к заполнению")
        .required("Обязательно к заполнению"),

      // ...(isService && {
      //   sum: yup
      //     .number()
      //     .required("Обязательно к заполнению")
      //     .typeError("Обязательно к заполнению"),
      // }),
      // ...(isService && {
      //   phone2: yup
      //     .number()
      //     .required("Обязательно к заполнению")
      //     .typeError("Обязательно к заполнению"),
      // }),
      cc_number: yup
        .string()
        .test(
          "test-number", // this is used internally by yup
          "Credit Card number is invalid", //validation message
          (value) => valid.number(value).isValid
        )
        .required("Обязательно к заполнению")
        .typeError("Обязательно к заполнению"),

      ...(!isWithdrawal && {
        cc_name: yup
          .string()
          .required("Обязательно к заполнению")
          .typeError("Обязательно к заполнению"),
        cc_expiry_month: yup
          .string()
          .required("Обязательно к заполнению")
          .typeError("Обязательно к заполнению"),
        cc_expiry_year: yup
          .string()
          .required("Обязательно к заполнению")
          .typeError("Обязательно к заполнению"),
        cc_cvv: yup
          .string()
          .required("Обязательно к заполнению")
          .typeError("Обязательно к заполнению"),
      }),
    })
    .required();

  useEffect(() => {
    setGlobalCSSVariables();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      terms: [],
      offer: [],
    },
  });

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");

  const onSubmit = async (fields) => {
    setLoading(true);

    const responseSuccesful = await postData(
      fields.cc_number,
      fields.cc_expiry_month,
      20 + fields.cc_expiry_year,
      fields.cc_cvv
    );

    if (responseSuccesful) {
      checkStatusPoll(
        () => {
          setLoading(false);
          setSuccess(true);
        },
        () => {
          setLoading(false);
          setFail(true);
        },
        responseSuccesful
      );
    } else {
      setLoading(false);
      setFail(true);
    }
  };

  return (
    <div className="w-screen h-screen flex items-center justify-center bg-gray-200">
      <form
        className="flex p-4 lg:pt-[44px] lg:pl-[45px] lg:pb-[57px] lg:pr-[57px] bg-white rounded-[10px] shadow-sm relative w-screen h-screen lg:w-auto lg:h-auto flex-col lg:flex-row"
        onSubmit={handleSubmit(onSubmit)}
      >
        <CreditPlus
          register={register}
          errors={errors}
          control={control}
          isDeposit={isDeposit}
          isWithdrawal={isWithdrawal}
          isService={isService}
          transactionStatus={transactionStatus}
          withdrawalTransactionStatus={withdrawalTransactionStatus}
          isSuccess={isSuccess}
          isFail={isFail}
        />
        <Withdrawal
          register={register}
          control={control}
          onSubmit={onSubmit}
          errors={errors}
          isLoading={isLoading}
          isSuccess={isSuccess}
          isFail={isFail}
          isDeposit={isDeposit}
          isWithdrawal={isWithdrawal}
          isService={isService}
          transactionStatus={transactionStatus}
        />
        {isDeposit && !isFail && !isSuccess && (
          <span className="relative lg:absolute text-[10px] text-[#BFBFBF] lg:left-[64px] bottom-[-18px] mb-12 lg:mb-0 lg:self-start self-center lg:bottom-[18px]">
            *Дополнительно взымается комиссия банка эмитента, чтобы узнать
            подробнее, обратитесь к своему банку
          </span>
        )}
      </form>
    </div>
  );
}

const CreditPlus = ({
  register,
  errors,
  isDeposit,
  isWithdrawal,
  isService,
  control,
  transactionStatus,
  withdrawalTransactionStatus,
  isSuccess,
  isFail,
}) => {
  let label1 = "Вход на сайт МФО";
  let label2 = "Выдача средств";
  let label3 = "Успешная оплата";

  if (isDeposit) {
    label1 = "Вход на сайт МФО";
    label2 = "Пополнение и погашение";
    label3 = "Успешная оплата";
  }

  if (isService) {
    label1 = "Выбор оператора";
    label2 = "Пополнение сервиса";
    label3 = "Успешная оплата";
  }

  return (
    <div className="flex flex-col p-0 lg:pb-[21px] lg:pr-[29px] lg:pl-[19px] lg:pt-[25px] w-full _w-[calc(100%-32px)] lg:w-[361px] _max-w-[313px] justify-between mb-12 lg:mb-0">
      <div className="flex flex-col h-full">
        <div className="text-[25px] font-semibold mb-[27px] text-primary">
          Credit Plus
        </div>
        <div className="flex items-center pb-[calc(10px+29px+21px)] gap-x-[8px] px-[22px]">
          <Item past label={label1} />
          <Separator />
          <Item past={isSuccess} selected={!isSuccess} label={label2} />
          <Separator />
          <Item selected={isSuccess} label={label3} />
        </div>
        <div className="flex flex-col mt-[34px]">
          <div className="flex flex-col gap-y-[9px]">
            <Field
              name="name"
              // register={register}
              // errors={errors}
              label="ФИО пользователя"
              type="email"
              value={
                isWithdrawal
                  ? withdrawalTransactionStatus.beneficiary_agent.full_name
                  : transactionStatus.metadata &&
                    transactionStatus.metadata.full_name
              }
              lg={true}
              icon="user"
              dontRegister
            />
            <Field
              name="phone"
              label="Номер телефона"
              type="tel"
              value={
                isWithdrawal
                  ? withdrawalTransactionStatus.beneficiary_agent.phone
                  : isService
                  ? transactionStatus &&
                    (transactionStatus.metadata.agent_phone ||
                      transactionStatus.metadata.phone_number ||
                      transactionStatus.metadata.phone)
                  : transactionStatus &&
                    (transactionStatus.metadata.phone_number ||
                      transactionStatus.metadata.phone)
              }
              lg={true}
              // register={register}
              // errors={errors}
              icon="call"
              dontRegister
            />
          </div>
          <div className="w-full h-[1px] mt-[14px] bg-primary mb-[10px]" />
          {isWithdrawal && (
            <div className="flex items-center justify-between w-full text-primary text-[18px]">
              <span>Сумма к выдаче</span>
              <span>{withdrawalTransactionStatus.amount / 100} ₸</span>
            </div>
          )}
          {isDeposit && (
            <div className="flex flex-col gap-y-1">
              <div className="flex items-center justify-between w-full text-primary text-[15px]">
                <span>Сумма пополнения</span>
                <span className="">{`${transactionStatus.amount / 100} ${
                  transactionStatus.currency_symbol
                }`}</span>
              </div>
              <div className="flex items-center justify-between w-full text-primary text-[15px]">
                <span>Комиссия за пополнение</span>
                <span className="">{`${0} ${
                  transactionStatus.currency_symbol
                }`}</span>
              </div>
              <div className="flex items-center justify-between w-full text-primary text-[18px]">
                <span>Итого к оплате</span>
                <span className="text-black">{`${
                  transactionStatus.amount / 100
                } ${transactionStatus.currency_symbol}`}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        className={`hidden ${
          isSuccess || isFail ? "lg:hidden invisible" : "lg:block "
        }`}
      >
        <Checkboxes register={register} errors={errors} control={control} />
      </div>
    </div>
  );
};

const Item = ({ selected, past, label }) => (
  <div className="flex flex-col relative">
    <div className="w-[25px] h-[25px] rounded-full bg-primary flex items-center justify-center">
      {selected && <div className="w-[13px] h-[13px] rounded-full bg-white" />}
      {past && (
        <div className="w-[13px] h-[13px] rounded-full bg-white opacity-50" />
      )}
    </div>
    <span className="text-center text-primary absolute left-1/2 transform -translate-x-1/2  text-[14px] w-[95px] top-[calc(25px+10px)]">
      {label}
    </span>
  </div>
);

const Separator = () => <div className="w-full h-[1px] bg-primary" />;

const Field = ({
  label,
  value = "",
  placeholder = "",
  className = "",
  lg = false,
  register = () => {},
  name = "",
  errors = {},
  type,
  icon,
  isMastercard = false,
  isVisa = false,
  field = {},
  dontRegister = false,
  ...props
}) => (
  <div className={`flex flex-col ${className} relative`}>
    {label && (
      <span className="text-[14px]  mb-[4px] text-primary">{label}</span>
    )}
    {icon === "user" && (
      <UserIcon className="absolute top-[37px] left-[10.5px] fill-primary stroke-primary w-[24px] h-[24px]" />
    )}
    {icon === "call" && (
      <CallIcon className="absolute top-[37px] left-[11px] fill-primary stroke-primary w-[24px] h-[24px]" />
    )}

    {icon === "cc" && (isVisa || isMastercard) && (
      <img
        src={isVisa ? "/img/icons/visa.svg" : "/img/icons/mastercard.svg"}
        alt={isVisa ? "visa" : "mastercard"}
        className={`absolute ${
          isVisa
            ? "top-[42px] left-[11px]  h-[10px]"
            : "top-[38px] left-[11px]  h-[15px]"
        }`}
      />
    )}
    <input
      type={type}
      className={`py-2.5 px-3.5 font-medium rounded-[10px] ${
        lg ? "text-[18px]  bg-primary-2" : "text-[16px]  bg-primary-3"
      }
        ${icon === "user" || icon === "call" ? "pl-11" : ""}
        ${icon === "cc" && (isVisa || isMastercard) ? "pl-12" : ""}
        `}
      disabled={dontRegister}
      value={dontRegister ? value : field.value}
      {...(!dontRegister && register(name))}
      {...(!dontRegister && field)}
      // {...register(name)}
      // {...field}
      placeholder={placeholder}
      {...props}
    />
    {errors[name] && (
      <p role="alert" className="text-sm text-red-500">
        {errors[name].message}
      </p>
    )}
  </div>
);
// <div className="relative">
// </div>

const Withdrawal = ({
  register,
  errors,
  handleSubmit,
  isLoading,
  isSuccess,
  isFail,
  isDeposit,
  isWithdrawal,
  isService,
  control,
  transactionStatus,
}) => {
  let label = "Пополнение и погашение";
  let btnLabel = "Пополнить и погасить";

  if (isWithdrawal) {
    label = "Выдача средств";
    btnLabel = "Вывести средства на карту";
  }

  if (isService) {
    if (transactionStatus.metadata.service_id === 401) {
      label = "Пополнение баланса Beeline";
    }
    if (transactionStatus.metadata.service_id === 402) {
      label = "Пополнение баланса Activ";
    }
    if (transactionStatus.metadata.service_id === 403) {
      label = "Пополнение баланса Kcell";
    }
    btnLabel = "Пополнить сервис";
  }

  const number = useWatch({
    control,
    name: "cc_number", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
  });

  const getCardType = (number) => {
    // Remove any non-digit characters from the number string
    const sanitizedNumber = number.replace(/\D/g, "");

    // Early identification for Visa
    if (sanitizedNumber.startsWith("4")) {
      return "Visa";
    }

    // Early identification for MasterCard
    if (/^5[1-5]/.test(sanitizedNumber) || /^2[2-7]/.test(sanitizedNumber)) {
      return "MasterCard";
    }

    return "Unknown";
  };

  const cardType = number && getCardType(number);

  const isVisa = cardType === "Visa";
  const isMastercard = cardType === "MasterCard";

  const theme = getTheme();

  return (
    <div className="flex flex-col justify-between pl-0 lg:pl-[58px] _pr-[59px] 523 w-full lg:w-[640px] w-[calc(100%-32px)]">
      <div className="flex flex-col h-full">
        {!isFail && !isSuccess && (
          <div className="font-semibold text-[28px] mb-[15px] text-primary hidden lg:block">
            {isLoading ? "Ожидание платежа..." : ""}
            {isSuccess ? "Оплата прошла успешно" : ""}
            {isFail ? "Ошибка" : ""}
            {!isLoading && !isSuccess && !isFail ? label : ""}
          </div>
        )}
        {isLoading && (
          <div className="flex flex-col items-center justify-center z-10 fixed bg-white lg:relative top-0 left-0 h-screen w-screen lg:w-full lg:h-full">
            <Oval
              height={110}
              width={110}
              // radius="9"
              color={theme.primary}
              secondaryColor={theme["primary-3"]}
              // ariaLabel="three-dots-loading"
              // wrapperStyle
              // wrapperClass
            />
          </div>
        )}
        {isSuccess && (
          <div className="flex flex-col items-center justify-center z-10 fixed bg-white lg:relative top-0 left-0 h-screen w-screen lg:w-full lg:h-full">
            <div className="w-[110px] h-[110px] bg-green-500 rounded-full flex items-center justify-center">
              <CheckIcon className="w-16 h-16 text-white" />
            </div>
            <span className="text-green-600 text-xl font-medium mt-3">
              Оплата прошла успешно!
            </span>
          </div>
        )}
        {isFail && (
          <div className="flex flex-col items-center justify-center z-10 fixed bg-white lg:relative top-0 left-0 h-screen w-screen lg:w-full lg:h-full">
            <div className="w-[110px] h-[110px] bg-red-500 rounded-full flex items-center justify-center">
              <XMarkIcon className="w-12 h-12 text-white" />
            </div>
            <span className="text-red-600 text-xl font-medium mt-3">
              Ошибка оплаты
            </span>
          </div>
        )}
        {!isLoading && !isSuccess && !isFail && (
          <>
            <div className="self-center hidden lg:block">
              <CreditCards
                isDeposit={isDeposit}
                isWithdrawal={isWithdrawal}
                isService={isService}
                control={control}
              />
            </div>
            <div className="flex flex-col lg:flex-row justify-between gap-x-[27px] w-full">
              <div className="flex flex-col w-full lg:w-[287px] gap-y-[38px] mb-[35px]">
                <div className="block lg:hidden">
                  {isService && (
                    <Field
                      name="sum"
                      register={register}
                      errors={errors}
                      label="Сумма пополнения"
                      type="number"
                      placeholder="2000 ₸"
                      dontRegister
                      value={transactionStatus.amount / 100}
                    />
                  )}
                </div>
                {isService && (
                  <Field
                    name="phone2"
                    register={register}
                    errors={errors}
                    label="Номер телефона"
                    type="tel"
                    placeholder="+77270003920"
                    dontRegister
                    value={
                      transactionStatus.metadata &&
                      transactionStatus.metadata.phone
                    }
                  />
                )}

                <Controller
                  name="cc_number"
                  control={control}
                  rules={{
                    validate: (value) =>
                      value.replace(/\s+/g, "").length <= 16 ||
                      "Must be 16 digits or less",
                  }}
                  render={({ field }) => (
                    <Field
                      name="cc_number"
                      register={register}
                      errors={errors}
                      label="Номер карты"
                      // type="number"
                      // placeholder="4400 4400 4400 4400"
                      // type="tel"
                      inputmode="numeric"
                      pattern="[0-9\s]{13,19}"
                      autocomplete="cc-number"
                      maxlength="16"
                      placeholder="xxxx xxxx xxxx xxxx"
                      icon="cc"
                      isVisa={isVisa}
                      isMastercard={isMastercard}
                      field={field}
                      onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, ""); // Remove non-digits
                        field.onChange(value.slice(0, 16)); // Limit to 16 digits
                      }}
                      type="text"
                      inputMode="numeric"
                      autoComplete="cc-number"
                      maxLength="19"
                    />
                  )}
                />
                {!isWithdrawal && (
                  <Field
                    name="cc_name"
                    register={register}
                    errors={errors}
                    label="Имя владельца"
                    type="text"
                    placeholder="Ваше имя"
                    maxLength="60"
                  />
                )}
              </div>
              <div className="flex flex-col w-full lg:w-[196px]  gap-y-[38px] mb-[35px]">
                {isService && (
                  <div className="lg:block hidden">
                    <Field
                      name="sum"
                      register={register}
                      errors={errors}
                      label="Сумма пополнения"
                      type="number"
                      placeholder="2000 ₸"
                      dontRegister
                      value={transactionStatus.amount / 100}
                    />
                  </div>
                )}
                {!isWithdrawal && (
                  <div className="flex flex-col">
                    <span className="text-[14px] mb-[4px] text-primary">
                      Срок действия
                    </span>
                    <div className="flex gap-x-[19px]">
                      <Controller
                        name="cc_expiry_month"
                        control={control}
                        rules={{
                          validate: (value) =>
                            value.length === 2 || "Must be 2 digits",
                        }}
                        render={({ field }) => (
                          <Field
                            type="number"
                            name="cc_expiry_month"
                            register={register}
                            errors={errors}
                            className="w-[calc(50%-9.5px)] lg:w-[90px]"
                            placeholder="ММ"
                            maxLength="2"
                            onChange={(e) => {
                              const value = e.target.value.replace(/\D/g, ""); // Remove non-digits
                              field.onChange(value.slice(0, 2)); // Limit to 2 digits
                            }}
                            field={field}
                          />
                        )}
                      />
                      <Controller
                        name="cc_expiry_year"
                        control={control}
                        rules={{
                          validate: (value) =>
                            value.length === 2 || "Must be 2 digits",
                        }}
                        render={({ field }) => (
                          <Field
                            type="number"
                            name="cc_expiry_year"
                            register={register}
                            errors={errors}
                            className="w-[calc(50%-9.5px)] lg:w-[90px]"
                            placeholder="ГГ"
                            maxLength="2"
                            onChange={(e) => {
                              const value = e.target.value.replace(/\D/g, ""); // Remove non-digits
                              field.onChange(value.slice(0, 2)); // Limit to 2 digits
                            }}
                            field={field}
                          />
                        )}
                      />
                    </div>
                  </div>
                )}
                {!isWithdrawal && (
                  <Controller
                    name="cc_cvv"
                    control={control}
                    rules={{
                      validate: (value) =>
                        value.length === 3 || "Must be 3 digits",
                    }}
                    render={({ field }) => (
                      <Field
                        name="cc_cvv"
                        register={register}
                        errors={errors}
                        label="CVV-код"
                        type="number"
                        placeholder="123"
                        maxLength="3"
                        onChange={(e) => {
                          const value = e.target.value.replace(/\D/g, ""); // Remove non-digits
                          field.onChange(value.slice(0, 3)); // Limit to 2 digits
                        }}
                        field={field}
                      />
                    )}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {!isSuccess && !isFail && (
        <div className="block lg:hidden mb-8">
          <Checkboxes register={register} errors={errors} control={control} />
        </div>
      )}
      {!isFail && !isSuccess && (
        <Button disabled={isLoading}>{btnLabel}</Button>
      )}
    </div>
  );
};

const Button = ({ children, disabled, onClick }) => (
  <button
    type="submit"
    onClick={() => {
      // if (!disabled) onClick();
    }}
    className={`bg-primary h-[50px] flex items-center justify-center text-[18px] text-white rounded-[10px] ${
      disabled ? "opacity-50 cursor-disabled" : ""
    }`}
  >
    {children}
  </button>
);

const CreditCards = ({ isDeposit, isWithdrawal, isService, control }) => {
  const name = useWatch({
    control,
    name: "cc_name", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: "Ваше имя", // default value before the render
  });
  const number = useWatch({
    control,
    name: "cc_number", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: "**** **** **** ****", // default value before the render
  });
  const month = useWatch({
    control,
    name: "cc_expiry_month", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: "**", // default value before the render
  });
  const year = useWatch({
    control,
    name: "cc_expiry_year", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: "**", // default value before the render
  });
  const cvv = useWatch({
    control,
    name: "cc_cvv", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: "***", // default value before the render
  });

  const sanitizedNumber = number.replace(/\s+/g, "");
  // Ensure the sanitized number is exactly 16 characters long by padding with '*'
  const paddedNumber = sanitizedNumber.padEnd(16, "*");
  // Split the padded number into chunks of 4
  const chunks = paddedNumber.match(/.{1,4}/g);

  const transformName = (fullName) => {
    const [firstName, lastName] = fullName.split(" ");

    if (!firstName || !lastName) {
      return fullName; // Return the original string if the format is unexpected
    }

    return `${firstName} ${lastName.charAt(0)}.`;
  };

  return (
    <div
      className={`relative mb-[36.66px] self-center ${
        isWithdrawal ? "w-[227.43px] h-[146.34px]" : "w-[316px] h-[158.46px]"
      } text-white text-[14px]`}
    >
      <div className="z-10 card w-[227.43px] h-[146.34px] rounded-[14.2px] bg-primary absolute top-0 left-0 flex flex-col justify-between pt-[14px] pl-[15px] pb-[12.34px] pr-[21.43px]">
        <span className="text-[11.89px] font-bold">Turbo Credit</span>
        <div className="flex items-center justify-between font-medium text-[16.42px]">
          {chunks.map((chunk, index) => (
            <span key={index}>{chunk}</span>
          ))}
        </div>
        <div className="flex gap-x-[17px]">
          {!isWithdrawal && (
            <div className="flex flex-col">
              <span className="text-[10px] mb-[3px] font-light">
                Владелец карты
              </span>
              <span className="font-medium">
                {name === "Ваше имя" ? "Ваше имя" : transformName(name)}
              </span>
            </div>
          )}
          {!isWithdrawal && (
            <div className="flex flex-col">
              <span className="text-[10px] mb-[3px] font-light">
                Срок действия
              </span>
              <span className="font-medium">
                {month}/{year}
              </span>
            </div>
          )}
        </div>
      </div>
      {!isWithdrawal && (
        <div className="w-[227.43px] h-[146.34px] rounded-[14.2px] bg-primary top-[12.12px] left-[88.57px] absolute flex flex-col items-end pt-[16.88px] pr-[33px] pb-[24.46px] justify-between">
          <img src="/img/chip.svg" className="" alt="cc" />

          <div className="flex flex-col">
            <span className="text-[10px] mb-[3px]">CVV</span>
            <span>{cvv}</span>
          </div>
        </div>
      )}
    </div>
  );
};

// export default App;
