import { useState, useEffect } from "react";
import { Form, LoadingScreen } from "../components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { setGlobalCSSVariables } from "../helpers";

const phoneRegExp = /^[\d\s\-()+]+$/;
const phoneSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .required("Неверный номер телефона")
    .matches(phoneRegExp, "Неверный номер телефона"),
});

const otpSchema = yup.object().shape({
  otp: yup
    .string()
    .required("OTP is required")
    .matches(/^\d{6}$/, "OTP must be exactly 6 digits"),
});

const WithdrawPage = () => {
  const [transactionStatus, setTransactionStatus] = useState(undefined);
  const [isLoadingInitialStatus, setIsLoadingInitialStatus] = useState(true);
  const [transactionStatusErr, setTransactionStatusErr] = useState(false);

  const [polling, setPolling] = useState(null);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [step, setStep] = useState(1);
  const [isVerified, setIsVerified] = useState(false);

  const [token, setToken] = useState(undefined);

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");

  // 1. get transaction status (receive client name and phone number)
  const getTransactionStatus = async (id) => {
    setIsLoadingInitialStatus(true);
    const response = await fetch(
      `https://api.ems.test.emoney.tools/api/v1/mfo/public/transaction?id=${id}`
    );
    const data = await response.json();

    if (response.status === 200) {
      setTransactionStatus(data);
    } else {
      // setTransactionStatusErr(data.message);
      setTransactionStatusErr(true);
    }

    setIsLoadingInitialStatus(false);
  };

  useEffect(() => {
    if (id) {
      getTransactionStatus(id);
    }
  }, [id]);

  useEffect(() => {
    setGlobalCSSVariables();
  }, []);

  const {
    register: registerPhone,
    handleSubmit: handleSubmitPhone,
    formState: { errors: errorsPhone },
  } = useForm({ resolver: yupResolver(phoneSchema) });

  const {
    register: registerOtp,
    handleSubmit: handleSubmitOtp,
    formState: { errors: errorsOtp },
  } = useForm({ resolver: yupResolver(otpSchema) });

  const handlePhoneSubmit = async (data) => {
    try {
      const response = await fetch(
        "https://api.ems.test.emoney.tools/api/v1/verification",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ phone_number: data.phoneNumber }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        setPhoneNumber(data.phoneNumber);
        setStep(2);
      } else {
        alert(result.description);
        console.error("Failed to send phone number");
      }
    } catch (error) {
      console.error("Error sending phone number:", error);
    }
  };

  const handleOtpSubmit = async (data) => {
    try {
      const response = await fetch(
        "https://api.ems.test.emoney.tools/api/v1/verification",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phone_number: phoneNumber,
            secret: data.otp,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        setToken(result.token);
        setIsVerified(true);
      } else {
        alert(result.description);
        console.error("Failed to send phone number");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };

  const postData = async (pan) => {
    let isSuccess;

    const response = await fetch(
      "https://api.ems.test.emoney.tools/api/v1/mfo/transaction",
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}; __ddg1_=321`,
          // Authorization: `Bearer ${token}`,
          // Cookie: "__ddg1_=rIlkXaaBT9iglN0fTzKd",
        },
        body: JSON.stringify({
          id: parseInt(id),
          card: pan,
        }),
      }
    );

    const result = await response.json();

    if (response.status === 200 || response.status === 201) {
      isSuccess = true;
    } else {
      isSuccess = false;
    }

    return isSuccess ? result.transaction.id : false;
  };

  // 3. check status
  const checkStatus = async (onSuccess, onFail, newTransId) => {
    try {
      const response = await fetch(
        `https://api.ems.test.emoney.tools/api/v1/mfo/public/transaction?id=${newTransId}`
      );
      const data = await response.json();

      if (data.status === 0) {
      } else if (data.status === 400 || response.status === 404) {
        onFail();
        clearInterval(polling);
      } else if (data.status === 600) {
        onSuccess();
        clearInterval(polling);
      }
    } catch (error) {
      console.error("Polling error:", error);
    }
  };

  if (isLoadingInitialStatus) {
    return <LoadingScreen />;
  }

  if (transactionStatusErr) {
    return (
      <div>
        Transaction not found
        <span />
      </div>
    );
  }

  if (isVerified) {
    return (
      <Form
        type="withdraw"
        postData={postData}
        checkStatusPoll={(onSuccess, onFail, newId) => {
          setPolling(
            setInterval(() => checkStatus(onSuccess, onFail, newId), 5000)
          );
        }}
        withdrawalTransactionStatus={transactionStatus}
      />
    );
  }

  return (
    <div className="w-screen h-screen flex items-center justify-center bg-gray-200">
      <div className="flex flex-col p-4 lg:p-8 bg-white rounded-[10px] shadow-sm relative w-screen h-screen lg:w-auto lg:h-auto flex-col lg:flex-row">
        {step === 1 && (
          <div>
            <div className="font-semibold text-[28px] mb-[15px] text-primary">
              Введите номер телефона
            </div>
            <form onSubmit={handleSubmitPhone(handlePhoneSubmit)}>
              <div className={`flex flex-col relative mb-8`}>
                {/*
                <span className="text-[14px]  mb-[4px] text-primary">
                  Номер телефона
                </span>
*/}
                <input
                  placeholder="77059101028"
                  className={`py-2.5 px-3.5 font-medium rounded-[10px] text-[18px]  bg-primary-3`}
                  type="tel"
                  {...registerPhone("phoneNumber")}
                />
                {errorsPhone.phoneNumber && (
                  <p role="alert" className="text-sm text-red-500">
                    {errorsPhone.phoneNumber.message}
                  </p>
                )}
              </div>
              <button
                className={`w-full bg-primary h-[50px] flex items-center justify-center text-[18px] text-white rounded-[10px]`}
                type="submit"
              >
                Запросить проверочный код
              </button>
            </form>
          </div>
        )}
        {step === 2 && (
          <div>
            <div className="font-semibold text-[28px] mb-[15px] text-primary">
              Введите проверочный код
            </div>

            <form onSubmit={handleSubmitOtp(handleOtpSubmit)}>
              <div className={`flex flex-col relative mb-8`}>
                {/*
                <span className="text-[14px]  mb-[4px] text-primary">
                  Номер телефона
                </span>
*/}
                <input
                  placeholder="Проверочный код"
                  className={`py-2.5 px-3.5 font-medium rounded-[10px] text-[18px]  bg-primary-3`}
                  type="text"
                  maxLength="6"
                  {...registerOtp("otp")}
                />
                {errorsOtp.otp && (
                  <p role="alert" className="text-sm text-red-500">
                    {errorsOtp.otp.message}
                  </p>
                )}
              </div>
              <button
                className={`w-full bg-primary h-[50px] flex items-center justify-center text-[18px] text-white rounded-[10px]`}
                type="submit"
              >
                Далее
              </button>
            </form>
          </div>
        )}
        {isVerified && <h2>OTP Verified Successfully!</h2>}
      </div>
    </div>
  );
};

export default WithdrawPage;
